import { Link, graphql } from "gatsby";
import Page from "../components/page";
import PropTypes from "prop-types";
import React from "react";
import SEO from "../components/seo";
import WYSIWYG from "../components/wysiwyg";

const ServiceDetailPage = ({ data }) => 
  <Page>
    <SEO title={`${data.service.name} - Aanbod`} />
    <div className='container px-8 md:flex mb-12 lg:mb-24 mt-4 lg:mt-16'>
      <div className='md:w-1/2' data-aos='fade-right'>
        <img
          className='mb-4 md:mb-0 w-full'
          loading='lazy'
          src={data.service.image.fluid.src}
          alt={data.service.name}
          title={data.service.name}
        />
      </div>
      <div
        className='pt-8 md:pt-6 lg:pt-12 xl:pt-16 md:pl-8 lg:pl-16 w-full md:w-1/2'
        data-aos='fade-left'
      >
        <h2 className='title mb-6'>{data.service.name}</h2>
        <WYSIWYG data={data.service.description.json} />
        <Link
          className='mt-4 md:mt-8 italic text-secondary hover:text-blue-600 text-sm transition-color underline'
          to='/aanbod'
        >
          Terug naar overzicht
        </Link>
      </div>
    </div>
  </Page>;


ServiceDetailPage.propTypes = {
  data: PropTypes.object.isRequired
};

export default ServiceDetailPage;

export const pageQuery = graphql`
  query($id: String) {
    service: contentfulService(id: { eq: $id }) {
      id
      name
      description {
        json
      }
      image {
        fluid {
          src
        }
      }
    }
  }
`;
